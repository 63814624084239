import { render, staticRenderFns } from "./CreateCertificateReanalysisRequestButton.vue?vue&type=template&id=04f53517&scoped=true&"
import script from "./CreateCertificateReanalysisRequestButton.vue?vue&type=script&lang=js&"
export * from "./CreateCertificateReanalysisRequestButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f53517",
  null
  
)

export default component.exports