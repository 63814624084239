<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-2 mt-2"
        fab
        dark
        x-small
        color="#f5222d"
        v-bind="attrs"
        v-on="on"
        @click="decline()"
      >
        <v-icon> fas fa-times </v-icon>
      </v-btn>
    </template>
    <span>Откажи преанализ</span>
  </v-tooltip>
</template>

<script>
import { DECLINE_CERTIFICATE_REANALYSIS_REQUEST } from "@/store/certificateReanalysisRequests.module";
export default {
  props: ["certificateId"],

  methods: {
    decline() {
      let vm = this;

      vm.$store
        .dispatch(DECLINE_CERTIFICATE_REANALYSIS_REQUEST, vm.certificateId)
        .then((data) => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message,
          });

          vm.$emit("success");
        })
        .catch(({ data }) => {
          let error = "Нещо се обърка";

          if (data.errors) {
            let errors = Object.values(data.errors);

            if (errors.length) {
              error = errors[0];
            }
          }

          vm.$notification["error"]({
            message: error,
          });
        });
    },
  },
};
</script>

<style scoped></style>
