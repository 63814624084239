<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12">
          <h4>Продукти</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="headers.search"
            prepend-inner-icon="search"
            clearable
            :label="$t('A.SEARCH')"
          ></v-text-field>
        </v-col>
        <v-col cols="4"> </v-col>
        <v-col cols="4">
          <div v-on:click="downloadData()" class="btn btn-primary">
            Свали експорт
          </div>
        </v-col>
      </v-row>

      <v-row class="mb-6 t-header">
        <v-col> Име </v-col>
        <v-col> Партида </v-col>
        <v-col> Брой </v-col>
      </v-row>

      <div v-show="!isLoadingStats">
        <v-row class="mb-6 t-row" v-for="item of items.products" :key="item.id">
          <template v-if="item.name != null">
            <v-col>
              {{ item.name }}
            </v-col>
            <v-col>
              {{ item.batch }}
            </v-col>
            <v-col>
              {{ item.quantity }}
            </v-col>
          </template>
        </v-row>
      </div>

      <br /><br />

      <v-row no-gutters>
        <h4>Материали</h4>
      </v-row>

      <v-row class="mb-6 t-header">
        <v-col> Име </v-col>
        <v-col> Вътрешно партиден номер </v-col>
        <v-col> Партиден номер на производител </v-col>
        <v-col> Брой </v-col>
        <v-col> Статус </v-col>
        <v-col> Операции </v-col>
      </v-row>

      <div v-show="!isLoadingStats">
        <v-row
          class="mb-6 t-row"
          v-for="item of items.materials"
          :key="item.id"
        >
          <template v-if="item.name != null">
            <v-col> {{ item.type }} {{ item.name }} </v-col>
            <v-col>
              {{ item.batch }}
            </v-col>
            <v-col>
              {{ item.vendor_batch }}
            </v-col>
            <v-col>
              {{ item.quantity }}
            </v-col>
            <v-col>
              {{ item.statusName }}
            </v-col>
            <v-col>
              <CreateCertificateReanalysisRequestButton
                v-if="
                  ['osvoboden', 'reanalyzed'].includes(item.statusSlug) &&
                    ['Admin', 'Qualified', 'Quality Manager'].includes(
                      user.role
                    )
                "
                :certificateId="item.id"
                @success="fetchData"
              />
              <ApproveCertificateReanalysisRequestButton
                v-if="
                  item.statusSlug === 'awaiting_reanalysis_permission' &&
                    ['Admin', 'Qualified', 'Manager'].includes(user.role)
                "
                :certificateId="item.id"
                @success="fetchData"
              />
              <DeclineCertificateReanalysisRequestButton
                v-if="
                  item.statusSlug === 'awaiting_reanalysis_permission' &&
                    ['Admin', 'Qualified', 'Manager'].includes(user.role)
                "
                :certificateId="item.id"
                @success="fetchData"
              />
              <ProcessCertificateReanalysisRequestButton
                v-if="
                  item.statusSlug === 'awaiting_reanalysis' &&
                    ['Admin', 'Qualified', 'Quality Manager'].includes(
                      user.role
                    )
                "
                :certificateId="item.id"
                @success="fetchData"
              />
            </v-col>
          </template>
        </v-row>
      </div>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingStats"
      ></v-progress-linear>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { API_URL } from "@/common/config";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { GET_ALL_STATS, DOWNLOAD_STATS } from "@/store/statistics.module";
import CreateCertificateReanalysisRequestButton from "@/components/ActionButtons/CreateCertificateReanalysisRequestButton";
import ApproveCertificateReanalysisRequestButton from "@/components/ActionButtons/ApproveCertificateReanalysisRequestButton";
import DeclineCertificateReanalysisRequestButton from "@/components/ActionButtons/DeclineCertificateReanalysisRequestButton";
import ProcessCertificateReanalysisRequestButton from "@/components/ActionButtons/ProcessCertificateReanalysisRequestButton";

export default {
  name: "QuantityInStock",

  components: {
    CreateCertificateReanalysisRequestButton,
    ApproveCertificateReanalysisRequestButton,
    DeclineCertificateReanalysisRequestButton,
    ProcessCertificateReanalysisRequestButton
  },

  data() {
    return {
      search: "",
      action: "statistics",
      tableOptions: {
        showSearch: false,
        draggableCols: true,
        selectable: true,
        rowsPerPage: [2, 10, 20, 50],
        maxVisibleButtons: 5
      },
      items: [],
      totalItems: 0,
      totalPages: 0,
      headers: {
        columns: [
          {
            name: "id",
            cols: "1"
          },
          {
            name: "name",
            search: "",
            cols: ""
          },
          {
            name: "address",
            search: "",
            cols: ""
          }
        ],
        search: "",
        rows: 10,
        page: 1,
        order: "id",
        orderType: "asc"
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingStats"]),
    user: function() {
      return JSON.parse(window.localStorage.getItem("userData"));
    },
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    },
    visiblePages() {
      const range = [];
      let vm = this;

      let start, end;

      if (vm.headers.page < Math.ceil(vm.tableOptions.maxVisibleButtons / 2)) {
        start = 1;

        if (vm.tableOptions.maxVisibleButtons > vm.totalPages) {
          end = vm.totalPages;
        } else {
          end = vm.tableOptions.maxVisibleButtons;
        }
      } else if (
        vm.headers.page + Math.ceil(vm.tableOptions.maxVisibleButtons / 2) >
        vm.totalPages
      ) {
        start = vm.totalPages - vm.tableOptions.maxVisibleButtons + 1;
        end = vm.totalPages;
      } else {
        start =
          vm.headers.page - Math.floor(vm.tableOptions.maxVisibleButtons / 2);
        end =
          vm.headers.page + Math.floor(vm.tableOptions.maxVisibleButtons / 2);
      }

      for (let i = start; i <= end; i += 1) {
        range.push({
          name: i,
          isDisabled: i === vm.headers.page
        });
      }

      return range;
    }
  },
  watch: {
    "headers.search": _.debounce(function() {
      let vm = this;

      vm.fetchData();
    }, 250),
    "headers.rows": _.debounce(function(new_value) {
      let vm = this;
      new_value = parseInt(new_value);
      window.localStorage.setItem(vm.action + "_rows", new_value);

      vm.headers.page = 1;
      vm.fetchData();
    }, 250),
    "headers.page": _.debounce(function() {
      let vm = this;
      vm.fetchData();
    }, 250)
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Стока в наличност" }]);

    vm.fetchData();
  },
  methods: {
    getOptions: function() {
      let vm = this;

      vm.headers.order =
        window.localStorage.getItem(vm.action + "_order") || "id";
      vm.headers.orderType =
        window.localStorage.getItem(vm.action + "_orderType") || "asc";
      vm.headers.rows = parseInt(
        window.localStorage.getItem(vm.action + "_rows") || 10
      );
    },
    changeOrder: _.debounce(function(key) {
      let vm = this;

      if (vm.headers.order == key) {
        let oType = vm.headers.orderType == "asc" ? "desc" : "asc";

        vm.headers.orderType = oType;
      } else {
        vm.headers.order = key;
      }

      vm.headers.page = 1;
      window.localStorage.setItem(vm.action + "_order", vm.headers.order);
      window.localStorage.setItem(
        vm.action + "_orderType",
        vm.headers.orderType
      );

      vm.fetchData();
    }, 250),
    fetchData: _.debounce(function() {
      let vm = this;

      vm.$store
        .dispatch(GET_ALL_STATS, { search: vm.headers.search })
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "dashboard" });
          }
        });
    }, 500),
    downloadData: _.debounce(function() {
      let vm = this;

      vm.$store
        .dispatch(DOWNLOAD_STATS)
        .then(data => {
          window.open(API_URL + "statistics/stock-export/" + data.data.code);
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "dashboard" });
          }
        });
    }, 500)
  }
};
</script>
